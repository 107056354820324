import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import HeroComponent from "../components/HeroComponent"
import { isDesktop } from "react-device-detect"
import { Icon } from "@iconify/react"
import { useLayoutEffect } from "react"

const Index = ({ setButtonState, setHireButtonSize, location }) => {
  const buttonRef = useRef(null)

  const data = useStaticQuery(graphql`
    query HomeQuery {
      allStrapiProject {
        nodes {
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 450)
              }
            }
          }
        }
      }
      allStrapiExperiment {
        nodes {
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 250)
              }
            }
          }
          video_link
          has_gif
        }
      }
      strapiHomepage {
        hero_headline
        hero_subheadline
        body
        seo {
          metaTitle
          metaDescription
          preventIndexing
          sharedImage {
            alt
            media {
              url
            }
          }
        }
      }
    }
  `)

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }
    return array
  }

  const strapiData = data.strapiHomepage
  const strapiSeo = strapiData.seo
  const writtenContent = strapiData.body
  const heroHeadline = strapiData.hero_headline
  const heroSubHeadline = strapiData.hero_subheadline
  const heroGalleryData = data.allStrapiExperiment.nodes.concat(
    data.allStrapiProject.nodes
  )
  const [galleryData, setGalleryData] = useState(null)
  const resizeHandler = () => {
    setHireButtonSize(buttonRef.current.getBoundingClientRect())
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [location])

  useLayoutEffect(() => {
    setGalleryData(shuffle(heroGalleryData))
  }, [])

  return (
    <div className="home-content">
      <Seo
        title={strapiSeo.metaTitle}
        description={strapiSeo.metaDescription}
        image={strapiSeo.sharedImage.media.url}
        imageAlt={strapiSeo.sharedImage.alt}
        preventIndexing={strapiSeo.preventIndexing}
      />
      <div className="buttonWrapper">
        <div className="buttonContainer">
          <Link to="/about">
            <button
              className="hero-button"
              ref={buttonRef}
              onMouseEnter={() => {
                setButtonState(true)
              }}
              onMouseLeave={() => {
                setButtonState(false)
              }}
              onClick={() => {
                setButtonState(false)
              }}
            >
              Reach out
            </button>
          </Link>
        </div>
      </div>
      <div className="home">
        <div className="hero">
          <div className="hero-text">
            <h1>{heroHeadline}</h1>
            <h3>{heroSubHeadline}</h3>
          </div>
          {/* <GatsbyImage className={heroImage} image={heroImage} alt="" /> */}
          <div className="hero-mosaic">
            {isDesktop &&
              galleryData &&
              galleryData.map((item, index) => {
                return <HeroComponent data={item} key={index} />
              })}
          </div>
          <div className="scroll-caret">
            <Icon icon="ant-design:caret-down-filled" width="30px"></Icon>
          </div>
        </div>

        <main className="content">
          <ReactMarkdown children={writtenContent} />
        </main>
      </div>
    </div>
  )
}

export default Index
