import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useState, useLayoutEffect } from "react"

const HeroComponent = ({ data }) => {
  const [imageGridWidth, setImageGridWidth] = useState(null)
  const [imageGridHeight, setImageGridHeight] = useState(null)
  let imageData = getImage(data.cover.localFile)
  const getImageSize = image => {
    if (image > 300) {
      return 2
    }
    return 1
  }
  useLayoutEffect(() => {
    setImageGridWidth(getImageSize(imageData.width))
    setImageGridHeight(getImageSize(imageData.height))
  }, [imageData.width, imageData.height])
  return (
    <div
      className="hero-component-container"
      style={{
        gridColumnEnd: `span ${imageGridWidth}`,
        gridRowEnd: `span ${imageGridHeight}`,
      }}
    >
      {data.has_gif ? (
        <img src={data.video_link} alt="" />
      ) : (
        <GatsbyImage image={imageData} alt="" />
      )}
    </div>
  )
}

export default HeroComponent
